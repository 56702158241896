<template>
  <div>
    <base-header class="pb-6" type="">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 d-inline-block mb-0">Dashboard</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
              <li class="breadcrumb-item"><a href="#">Dashboard</a></li>
              <li class="breadcrumb-item active" aria-current="page">Dashboard</li>
            </ol>
          </nav>
        </div>
      </div>
      <!-- Card stats -->
      <div class="row">
       <div  class="col-lg-4 col-md-6 col-sm-6 col-12"> 
        <!--Profile card-->
          <div class="card card-profile">
            
            <img src="img/theme/profile_img.jpg" alt="Image placeholder" class="card-img-top">
            <div class="top-left">CoreGem ({{userInformation.userBranchName}})</div>
            <div class="top-right"></div>
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <span class="avatar-title rounded-circle">{{`${userInformation.userFirstName.charAt(0)}${userInformation.userLastName.charAt(0)}`}}</span>
                 </div>
              </div>
            </div>
             <div class="card-body pt-0">
              <div class="row">
                <div class="col">
                  <div class="card-profile-stats d-flex justify-content-center pt-5 pt-md-5 pb-0 pb-md-4">
                    <div class="text-center">
                <h5 class="h3">
                  {{`${userInformation.userFirstName} ${userInformation.userLastName}`}}<!--<span class="font-weight-light">, 27</span>-->
                </h5>
                <div class="h5 font-weight-600">
                  <i class="ni location_pin mr-2"></i>{{userInformation.role.roleName}}
                </div>
              </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          </div>
          <div  class="col-lg-8 col-md-6 col-sm-6 col-12"> 
            <div class="card">
              <div class="card-header">Transaction Volume</div>
         <div class="pt-1">
          <div class="card-body">
          <line-charts
            :chart-data="bigLineChart.chartData"
            
          >
          </line-charts>
          </div>
</div>
        </div>
          </div>
      </div>

    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--6">
      
      <!-- End charts-->

      <!--Tables & Widgets-->
      <div class="row">
        <div class="col-lg-8 col-md-6 col-sm-6 col-12">
          <div class="card">
            <div class="card-header">Success - Failed Transaction Count</div>
            <div class="pt-1">
          <bar-chart>
            </bar-chart>
            </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6 col-12">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="card">
            <div class="card-header">Yearly Transcation Volume</div>
            <div class="pt-2">
          <pie-chart  :style="myStyles">
          </pie-chart>
            </div>
              </div>
            </div>
          </div>
         
          
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="card">
            <div class="card-header">Yearly Transcation Volume</div>
            <div class="pt-2">
          <pie-chart :style="myStyles">
          </pie-chart>
            </div>
              </div>
            
            </div>
         
          </div>
        </div>
      </div>
      <!--End Tables & Widgets-->

      <!--Lists-->
      <div class="card-deck flex-column flex-xl-row">
      
        <!-- Progress track -->
        <card>
          <div class="card-header">Transcation Count</div>
            <div class="pt-0">
           <AreaChart/>
            </div>
        </card>
      </div>
      <!--End lists-->
    </div>

  </div>
</template>
<script>
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import LineCharts from './LineCharts';
  import AreaChart from "./AreaChart.vue";
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";

  // Components
  import BaseHeader from '@/components/BaseHeader';
  import BaseProgress from '@/components/BaseProgress';

  // Lists
  import ProgressTrackList from './ProgressTrackList';
  import MembersCard from '@/views/Widgets/MembersCard';
  import TaskList from '@/views/Widgets/TaskList';

  // Tables
  import LightTable from './LightTable';
  import VectorMapCard from '@/views/Widgets/VectorMapCard'
import { mapState, mapActions, mapGetters } from "vuex";

  export default {
    //name:"Alternative Dashboard",
    components: {
      LineCharts,
      //BarChart,
      BaseHeader,
      //TaskList,
      //VectorMapCard,
      //LightTable,
      //ProgressTrackList,
      //MembersCard,
      PieChart,
      AreaChart,
      BarChart
    },
    data() {
      return {


        
        bigLineChart: {
          chartData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct","Nov","Dec"],
        datasets: [
          {
            data: [86, 114, 106, 106, 107, 111, 133, 221, 783, 2478, 107, 111],
            label: "Card Transaction",
            borderColor: "#3e95cd",
            fill: true
          },
          {
            data: [282, 350, 411, 502, 635, 809, 947, 1402, 3700, 5267, 107, 111],
            label: "NIP",
            borderColor: "#8e5ea2",
            fill: true
          },
          {
            data: [168, 170, 178, 190, 203, 276, 408, 547, 675, 734, 107, 111],
            label: "Intra BAnk",
            borderColor: "#3cba9f",
            fill: true
          }
          
        ]
            
          },
          //extraOptions: chartConfigs.blueChartOptions,
        },
       
        
      }
    },
    computed: {
    ...mapState({userInformation: state => state.auth.userInfo}),
    

    myStyles () {
        return {height: '150px'}
    }
    }
  };
</script>
<style scoped>
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  font-weight: 600;
  color:white;
}
.top-right {
  position: absolute;
  top: 25%;
  right: 16px;
  font-weight: 600;
  color:white;
}
.avatar-title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #556ee6;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: 500;
    height: 100px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100px;
}
.card-profile-image span {
    max-width: 140px;
    border-radius: 0.375rem;
    border: 3px solid #fff;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    position: absolute;
    left: 50%;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}
span {
    vertical-align: middle;
    border-style: none;
}
.card-profile-image span:hover {
    -webkit-transform: translate(-50%, -50%) scale(1.03);
    transform: translate(-50%, -50%) scale(1.03);
}
</style>

